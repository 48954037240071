import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { init } from 'echarts';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  BsBarChartLine,
  BsCaretDown,
  BsCaretUp,
  BsCartCheck,
  BsCloudDownload,
  BsFileEarmarkExcel,
  BsFiletypeCsv,
  BsGearFill,
  BsStar,
} from 'react-icons/bs';

import { LayoutContext } from '../libs/contexts';

export const QuickTermQuoterCard = ({ footer }) => {

    const { layout } = useContext(LayoutContext);

    return (


        <Card>
            <Card.Header>
                Quick quote
            </Card.Header>
            <Card.Body>

                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" placeholder="Age (18-50)" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control type="text" placeholder="Face value (20k-500k)" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Select aria-label="Default select example">
                            <option>Gender</option>
                            <option value="F">Female</option>
                            <option value="M">Male</option>
                        </Form.Select>
                    </Form.Group>
                </Form>


            </Card.Body>
            {'full' === footer &&
                <Card.Footer>
                    <Button variant="outline-primary">Generate Link URL</Button>
                </Card.Footer>
            }
        </Card>
    )

}

export const QuickTermSimpleQuoter = ({ cancelButton }) => {

    const { layout } = useContext(LayoutContext);

    return (

        <>

            <Form>
                <Row className="mt-2 mb-1">
                    <Col className="col-8">
                        <Form.Control type="text" placeholder="Face value (20k-500k)" />
                    </Col>
                    <Col>
                        <Button variant="outline-success"><BsCaretUp /></Button>
                        <Button variant="outline-warning"><BsCaretDown /></Button>
                    </Col>
                </Row>
                <Row>
                    <p>...</p>
                </Row>
            </Form>

            {cancelButton}

        </>

    )

}


