import { useContext } from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { LayoutContext } from '../libs/contexts';

/**
 * A typical card with one optional button and footer
 * @param object options
 * @param string options.title Title, inside the body (not a card header)
 * @param string options.text Main text
 * @param object options.icon Icon component, most likely a react-icon component 
 * @param string options.label Optional button label
 * @param {*} options.callback Optional action, URL or a callback function. If not provided, there's no button.
 * @param string options.footer Optional footer text. If not provided, no footer.
 * @returns object component
 */
export const ActionCard = ({ title, text, icon, label, callback, footer }) => {

    return (
        <Card>
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                {icon ?
                    <SplitBody text={text} icon={icon} label={label} callback={callback} />
                    :
                    <SimpleBody text={text} label={label} callback={callback} />
                }
            </Card.Body>
            {footer &&
                <Card.Footer>{footer}</Card.Footer>
            }
        </Card>
    )

}

/**
 * A group of cards displayed in a typical grid
 * @param object options
 * @param object options.groupDefinition key/value where we expect values to be used to populate ActionCards
 * @returns object component
 */
export const ActionCardGroup = ({ groupDefinition }) => {

    return (
        <Container fluid>
            <Row className='mb-4'>
                {groupDefinition &&
                    <>
                        {Object.entries(groupDefinition).map(([cardId, card]) => (
                            <Col key={cardId} className='col-md-6 col-lg-4 col-xxl-3 mt-2'>
                                <ActionCard
                                    title={card.title}
                                    text={card.text}
                                    icon={card.icon}
                                    label={card.label}
                                    callback={card.callback}
                                    footer={card.footer}
                                />
                            </Col>
                        ))}
                    </>
                }
            </Row>
        </Container>
    );

}
export const BasicCard = ({ header, body, icon, footer }) => {

    return (
        <Card className="mb-2">
            {header &&
                <Card.Header>
                    {header}
                </Card.Header>
            }
            <Card.Body>
                <SplitBody text={body} icon={icon} />
            </Card.Body>
            {footer &&
                <Card.Footer>{footer}</Card.Footer>
            }
        </Card>
    )

}

export const InfoCard = ({ header, headerClass, subheader, title, text, icon, footer }) => {

    return (
        <Card className="mb-2">
            {header &&
                <Card.Header className={headerClass}>
                    {header} <small className="float-end">{subheader}</small>
                </Card.Header>
            }
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <SplitBody text={text} icon={icon} />
            </Card.Body>
            {footer &&
                <Card.Footer>{footer}</Card.Footer>
            }
        </Card>
    )

}

/**
 * Optional button that has either a URL or function action
 * @param object options
 * @param object options.label Button label
 * @param {*} options.callback URL or function. If no callback, no button.
 * @returns object component
 */
const SmartButton = ({ label, callback }) => {

    return (<>
        {callback &&
            <>
                {callback instanceof Function ?
                    <Button onClick={callback} variant="primary" className="mt-4">{label}</Button>
                    :

                    <Link to={callback}>
                        <Button variant="primary" className="mt-4">{label}</Button>
                    </Link>
                }
            </>
        }
    </>);
}

/**
 * Simple card body with text and optional button
 * @param object options
 * @param string options.text Main text
 * @param string options.label Button label
 * @param {*} options.callback URL or function. If no callback, no button.
 * @returns object component
 */
const SimpleBody = ({ text, label, callback }) => {
    return (
        <>
            {text instanceof String ?
                <Card.Text>
                    {text}
                </Card.Text>
                :
                <>{text}</>
            }
            <SmartButton label={label} callback={callback} />
        </>
    );
}

/**
 * Split layout card with a big icon on one side and text+button on the other
 * @param object options
 * @param string options.text Main text
 * @param object options.icon Icon, we expect a react-icon component
 * @param string options.label Button label
 * @param {*} options.callback URL or function. If no callback, no button.
 * @returns object component
 */
const SplitBody = ({ text, icon, label, callback }) => {

    const { layout } = useContext(LayoutContext);

    return (
        <Container fluid>
            <Row>
                <Col className="col-8 px-0 mx-0">
                    {text instanceof String ?
                        <Card.Text>
                            {text}
                        </Card.Text>
                        :
                        <>{text}</>
                    }
                    <div>
                        <SmartButton label={label} callback={callback} />
                    </div>
                </Col>
                <Col className="px-0 mx-0">
                    <h1 className={"big-icon-" + layout.scheme + " float-end"}>{icon}</h1>
                </Col>
            </Row>
        </Container>
    );
}
