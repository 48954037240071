import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { init } from 'echarts';
import {
    Button,
    Card,
    Col,
    Container,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {
    BsBarChartLine,
    BsCartCheck,
    BsCloudDownload,
    BsFileEarmarkExcel,
    BsFiletypeCsv,
    BsGearFill,
    BsStar,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';

import { LayoutContext } from '../libs/contexts';

export const LinkAdvisorCard = ({ footer }) => {


    const { layout } = useContext(LayoutContext);

    const alertClicked = () => {

    }

    return (


        <Card>
            <Card.Header>
                Link advisor
            </Card.Header>
            <Card.Body>
                Why not add regional contact information?
            </Card.Body>
            {'full' === footer &&
                <Card.Footer>
                    <Link to="/settings"><Button variant="outline-primary">Review settings</Button></Link>
                </Card.Footer>
            }
        </Card>



    )

}
