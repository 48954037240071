import {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap';

import { EventsCard } from '../app/events';
import { QuoterCard } from '../app/quoters';
import * as config from '../config.js';
import { DemographicsSummary } from '../controls/demographics.jsx';
import { MutualSummary } from '../controls/mutual.jsx';
import { PipelineSummary } from '../controls/pipeline.jsx';
import { FeaturedLeadsCard } from '../leads/featured';
import {
  LayoutContext,
  ReportingDataContext,
  SessionContext,
} from '../libs/contexts';
import { HistoryChartCard } from '../reports/history';
import { BasicInsightsCard } from '../reports/insights';
import { LinkAdvisorCard } from '../settings/advisor';

export const Dashboard = () => {

    const { layout } = useContext(LayoutContext);

    return (<>
        { layout.desktop ?
            <DesktopDashboard />
            :
            <MobileDashboard />
        }

    </>);
}

const DesktopDashboard = () => {

    const { reportingData } = useContext(ReportingDataContext);
    const { session, setSession } = useContext(SessionContext);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {



    }, []);

    useEffect(() => {

        if ("yes" === config.HasLinkAdmin) {
            if (reportingData && undefined !== reportingData.customization.admins) {
                if (undefined !== session.email) {

                    for (let admin of reportingData.customization.admins) {
                        if (admin === session.email) {
                            setIsAdmin(true);
                        }
                    }
                }
            }
        }

    }, [reportingData, session.email])

    return (
        <Container fluid>
            {/* <Row>
                <Col className='px-0 mx-0'>
                    <TopMenu />
                </Col>
            </Row> */}
            { isAdmin &&
                <Row className="mt-4">
                    <Col className='col-4'>
                        <PipelineSummary />
                    </Col>
                    <Col className='col-4'>
                        <DemographicsSummary />
                    </Col>
                    <Col className='col-4'>
                        <MutualSummary />
                    </Col>
                </Row>
            }
            <Row className="mt-4">
                <Col className="text-center">
                    <h5>Leads overview</h5>
                </Col>
            </Row>

            <Row className="mt-4">
                <Col className="col-md-12 col-lg-6">

                    <Container fluid>

                        <Row>
                            <Col>
                                <FeaturedLeadsCard footer='full' />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>

                                <BasicInsightsCard footer='full' />

                            </Col>
                            {/* <Col>
                                <Col>
                                    <EventsCard footer='full' />
                                </Col>
                            </Col> */}
                        </Row>
                    </Container>

                </Col>

                <Col className="col-md-12 col-lg-6">
                    <Container fluid>
                        <Row>
                            <Col>
                                <HistoryChartCard footer='full' />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            {/* <Col>
                                <BasicInsightsCard footer='full' />
                            </Col> */}
                            {/* <Col>
                                <LinkAdvisorCard footer='full' />
                            </Col> */}
                        </Row>
                    </Container>

                </Col>

            </Row>

        </Container>
    );

}

const DashboardCards = ({ }) => {

    return (
        <Container className='container-main' fluid>
            <Row>
                <Col className="desktop-area py-3">
                    <Container fluid>
                        <Row>
                            <Col className="offset-lg-2 offset-xl-3" >
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            header...
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card className='card-outlet'>
                                                <Card.Body>
                                                    Main content here
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>


        </Container>
    )
}

const MobileDashboard = () => {

    return (
        <Container fluid>
            <Row className="mt-4">
                <Col className="col-12 col-lg-6 mb-2">
                    <FeaturedLeadsCard footer='minimal' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <HistoryChartCard footer='none' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <QuoterCard footer='none' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <EventsCard footer='minimal' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <BasicInsightsCard footer='none' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <LinkAdvisorCard footer='none' />
                </Col>
            </Row>

        </Container>
    );

}