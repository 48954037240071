import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { init } from 'echarts';
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  BsBarChartLine,
  BsCartCheck,
  BsCloudDownload,
  BsFileEarmarkExcel,
  BsFiletypeCsv,
  BsFillPinMapFill,
  BsGearFill,
  BsGenderFemale,
  BsStar,
  BsUmbrella,
} from 'react-icons/bs';
import { MdOutlineAddIcCall } from 'react-icons/md';
import { Link } from 'react-router-dom';

import {
  LayoutContext,
  ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';

export const BasicInsightsCard = ({ footer }) => {

    const { layout } = useContext(LayoutContext);
    const { reportingData } = useContext(ReportingDataContext);
    const [insights, setInsights] = useState(false);

    useEffect(() => {
        if (undefined !== reportingData && undefined !== reportingData.reports && undefined !== reportingData.reports.all_time) {

            let leads = reportingData.reports.all_time.leads;
            let visitors = reportingData.reports.all_time.anonymous;
            let converted = Math.round(100 * (leads / (leads + visitors)));

            if (converted > 0) {
                setInsights(converted + "% of visitors provided contact information");
            } else {
                setInsights("(Not enough data)");
            }

        }

    }, [reportingData]);

    return (



        <Card>
            <Card.Header>
                Data insights
            </Card.Header>
            <Card.Body>
                {insights ?
                    <>{insights}</>
                    :

                    <>...</>
                }
            </Card.Body>
            {/* {'full' === footer &&
                <Card.Footer>
                    <Link to="/reports"><Button variant="outline-primary">More insights</Button></Link>
                </Card.Footer>
            } */}
        </Card>



    )

}

export const InsightsTable = () => {

    const { layout } = useContext(LayoutContext);

    const { reportingData } = useContext(ReportingDataContext);
    const [insights, setInsights] = useState(false);

    useEffect(() => {
        if (undefined !== reportingData && undefined !== reportingData.summary) {

            let newInsights = {};

            if (undefined !== reportingData.reports && undefined !== reportingData.reports.all_time) {
                let leads = reportingData.reports.all_time.leads;
                let visitors = reportingData.reports.all_time.anonymous;
                let converted = Math.round(100 * (leads / (leads + visitors)));
                newInsights.conversion = converted;

                if (undefined !== reportingData.summary) {
                    let provinces = {};
                    let genders = { F: 1, M: 1 };
                    let values = [];

                    for (let lead of reportingData.summary) {
                        if (undefined === provinces[lead.province]) {
                            provinces[lead.province] = 0;
                        }
                        provinces[lead.province]++;
                        if (undefined === genders[lead.gender]) {
                            genders[lead.gender] = 0;
                        }
                        genders[lead.gender]++;
                        if (lead.facevalue && !isNaN(parseInt(lead.facevalue.toString()))) {
                            values.push(lead.facevalue);
                        }
                    }

                    const sum = values.reduce((a, b) => a + b, 0);
                    newInsights.facevalue = (sum / values.length) || 0;
                    newInsights.facevalue = asCurrency({ amount: newInsights.facevalue, dropPennies: true });
                    let provinceCount = 0;
                    for (let [k, v] of Object.entries(provinces)) {
                        if (v > provinceCount) {
                            provinceCount = v;
                            newInsights.province = k;
                        }
                    }

                    newInsights.gender = Math.round(100 * (genders.F / (genders.F + genders.M)));
                    setInsights(newInsights);
                }


            }

        }
    }, [reportingData]);


    return (

        <Card>
            <Card.Header>
                Data insights
            </Card.Header>
            <Card.Body>
                {insights &&
                    <ListGroup variant="flush">
                        <ListGroup.Item>{insights.gender}% of your leads are female <span className="float-end text-muted"><BsGenderFemale /></span></ListGroup.Item>
                        <ListGroup.Item>{insights.province} is your most active province <span className="float-end text-muted"><BsFillPinMapFill /></span></ListGroup.Item>
                        <ListGroup.Item>Average face value requested is {insights.facevalue} <span className="float-end text-muted"><BsUmbrella /></span></ListGroup.Item>
                        <ListGroup.Item>{insights.conversion}% of your visitors left contact information<span className="float-end text-muted"><MdOutlineAddIcCall /></span></ListGroup.Item>
                    </ListGroup>
                }

            </Card.Body>
        </Card>



    )

}