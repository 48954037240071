import {
    useEffect,
    useState,
} from 'react';

import {
    Badge,
    ListGroup,
} from 'react-bootstrap';
import { BsCartPlus } from 'react-icons/bs';

import { InfoCard } from '../controls/cards';
import { ReportingDataContext } from '../libs/contexts';
import { asCurrency } from '../libs/i18n';
import { convertOffers } from '../libs/utils';

export const UpsellCard = ({ lead }) => {
    return (

        <InfoCard
            header="Offer enhancements"
            headerClass="bg-success text-white"
            subheader=""
            // subheader="Up to $19.36 more per month"
            text={ <UpsellOpportunities lead={ lead } /> }
            icon={ <BsCartPlus /> }
        />
    )
}


const UpsellOpportunities = ({ lead }) => {

    const [upsellData, setUpsellData] = useState(false);

    useEffect(() => {
        if (undefined !== lead.offers) {
            let offers = convertOffers({ valueStr: lead.offers });
            let newData = {};
            try {

                if (undefined !== lead.term_selected && lead.term_selected.includes("10")) {
                    newData.extend = true;
                    newData.extendTerm = 20;

                    newData.extendRider = offers.term20.base + offers.term20.rider;
                    newData.extendBase = offers.term20.base;

                    if (['no', 'No'].includes(lead.rider)) {
                        newData.rider = offers.term10.base + offers.term10.rider;
                    }


                } else {
                    newData.extend = false;
                    if (['no', 'No'].includes(lead.rider)) {
                        newData.rider = offers.term20.base + offers.term20.rider;
                    }
                }
            }
            catch (err) {
                //onsole.log(err);
            }
            setUpsellData(newData);

        }
    }, [lead]);

    return (
        <>
            <ListGroup variant="flush">
                { upsellData &&
                    <>
                        { upsellData.extend ?
                            <>
                                <ListGroup.Item>20 year term + rider ({ asCurrency({ amount: upsellData.extendRider }) } monthly)</ListGroup.Item>
                                <ListGroup.Item>20 year term, no rider ({ asCurrency({ amount: upsellData.extendBase }) } monthly)</ListGroup.Item>
                                { upsellData.rider &&
                                    <ListGroup.Item>Include rider ({ asCurrency({ amount: upsellData.rider }) } monthly)</ListGroup.Item>
                                }
                            </> :
                            <>
                                { upsellData.rider ?
                                    <ListGroup.Item>Include rider ({ asCurrency({ amount: upsellData.rider }) } monthly)</ListGroup.Item>
                                    :
                                    <Badge>no additional opportunities</Badge>
                                }
                            </> }
                    </>
                }
            </ListGroup>
        </>

    )

}
