import {
  useEffect,
  useState,
} from 'react';

import {
  Badge,
  Button,
  ListGroup,
} from 'react-bootstrap';
import {
  BsArrowLeftRight,
  BsCalculator,
} from 'react-icons/bs';

import { InfoCard } from '../controls/cards';
import { asCurrency } from '../libs/i18n';
import { convertOffers } from '../libs/utils';
import { QuickTermSimpleQuoter } from '../quoters/quickterm';

export const AlternativeCard = ({ lead }) => {

  const [view, setView] = useState('default');



  return (
    <>
      { 'default' === view ?
        <InfoCard
          header="Alternatives"
          headerClass="bg-warning text-white"
          subheader="Opportunities available"
          text={ <Alternatives lead={ lead } setView={ setView } /> }
          icon={ <BsArrowLeftRight /> }
        />
        :
        <>
          <InfoCard
            header="Instant quote"
            headerClass="bg-warning text-white"
            text={ <QuickTermSimpleQuoter cancelButton={ <Button onClick={ () => setView('default') }><BsCalculator /> Cancel</Button> } /> }
            icon={ <BsCalculator /> }
          />
        </>
      }

    </>
  )
}

const Alternatives = ({ lead, setView }) => {

  const [alternativesData, setAlternativesData] = useState(false);

  useEffect(() => {
    if (undefined !== lead.offers) {
      //todo refactor
      let offers = convertOffers({ valueStr: lead.offers });
      console.log("offers", offers);
      let newData = {
        alternatives: false
      };


      if (undefined !== lead.term_selected && lead.term_selected.includes('10')) {

        try {

          if (['yes', 'Yes'].includes(lead.rider)) {
            newData.alternatives = true;
            newData.noRider = offers.term10.base;
          }


        } catch (err) {
          console.log(err);
        }
      } else {

        if (undefined !== lead.term_selected && lead.term_selected.includes('20')) {

          try {
            newData.alternatives = true;
            newData.downgrade = true;

            newData.downgradeWithRider = offers.term10.rider + offers.term10.base;
            newData.downgradeWithNoRider = offers.term10.base;

            if (['yes', 'Yes'].includes(lead.rider)) {
              newData.noRider = offers.term20.base;
            }

          } catch (err) {
            console.log(err);
          }
        }

      }

      console.log("ALt", newData);

      setAlternativesData(newData);

    }

  }, [lead]);


  return (
    <>
      <ListGroup variant="flush">
        { alternativesData &&
          <>
            { alternativesData.downgrade ?
              <>
                <ListGroup.Item>10 year term + rider ({ asCurrency({ amount: alternativesData.downgradeWithRider }) } monthly)</ListGroup.Item>
                <ListGroup.Item>10 year term, no rider ({ asCurrency({ amount: alternativesData.downgradeWithNoRider }) } monthly)</ListGroup.Item>
                { alternativesData.noRider &&
                  <ListGroup.Item>Remove rider ({ asCurrency({ amount: alternativesData.noRider }) } monthly)</ListGroup.Item>
                }
              </> :
              <>
                { alternativesData.alternatives ?
                  <ListGroup.Item>Remove rider ({ asCurrency({ amount: alternativesData.noRider }) } monthly)</ListGroup.Item>
                  :
                  <Badge>no obvious alternatives</Badge>
                }
              </> }
          </>
        }
      </ListGroup>
    </>
  )

}