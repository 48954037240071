import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { init } from 'echarts';
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {
    BsBarChartLine,
    BsCartCheck,
    BsCloudDownload,
    BsFileEarmarkExcel,
    BsFiletypeCsv,
    BsGearFill,
    BsStar,
} from 'react-icons/bs';

import { LayoutContext } from '../libs/contexts';

export const EventsCard = ({ footer }) => {

    const { layout } = useContext(LayoutContext);


    const alertClicked = () => {

    }

    return (


        <Card>
            <Card.Header>
                Recent events
            </Card.Header>
            <Card.Body className="disabled text-muted">
                <ListGroup>
                    <ListGroup.Item><span className="text-muted">2023-11-08</span> New system user</ListGroup.Item>
                    <ListGroup.Item><span className="text-muted">2023-11-07</span> Updated customization options</ListGroup.Item>
                    <ListGroup.Item><span className="text-muted">2023-11-07</span> New system user</ListGroup.Item>
                    <ListGroup.Item><span className="text-muted">2023-11-06</span> Rates updated</ListGroup.Item>
                    <ListGroup.Item><span className="text-muted">2023-11-05</span> Rates updated</ListGroup.Item>
                </ListGroup>
            </Card.Body>
            {'full' === footer &&
                <Card.Footer>
                    <Button disabled className="disabled text-muted" variant="outline-primary">More events</Button>
                    <Badge className="float-end">not available in demo</Badge>
                </Card.Footer>
            }
        </Card>



    )

}