

export const getLayout = (next) => {
    let layout = {};
    if (next) {
        next(layout);
    }
}


export const pipelineStatus = [
    {
        name: 'Low interest',
        bg: 'danger',
        text: 'white',
        id: 'considered'
    },
    {
        name: 'Interested',
        bg: 'secondary',
        text: 'black',
        id: 'interested'
    },
    {
        name: 'Committed',
        bg: 'info',
        text: 'white',
        id: 'committed'
    },
    {
        name: 'Confirmed',
        bg: 'warning',
        text: 'black',
        id: 'confirmed'
    },
    {
        name: 'Pending',
        bg: 'primary',
        text: 'white',
        id: 'inception'
    },
    {
        name: 'Participating',
        bg: 'success',
        text: 'white',
        id: 'participating'
    }
];
