import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { QuoterCard } from '../app/quoters';
import { InfoCard } from '../controls/cards';
import { SimpleGrid } from '../controls/grids';
import { Hero } from '../controls/hero';
import { SettingsContext } from '../libs/contexts';

export const SalesArguments = () => {

    return (
        <>
            <Tips />
            <Objections />
        </>
    );
}


const Tips = () => {


    const { settings, setSettings } = useContext(SettingsContext);

    const onChange = ({ item, action }) => {
        //onsole.info("Change", action, item);
    }

    return (
        <>
            <Hero title="Sales Tips" text="Tips to display in the Lead page" />
            <Container fluid>
                <Row className="mb-4">
                    <Col className="col-6">
                        {settings && settings.closing && settings.closing.tips &&
                            <SimpleGrid allowDelete={true} allowInsert={true} rows={settings.closing.tips} onChange={onChange} />
                        }
                    </Col>

                </Row>
            </Container>
        </>
    )

}

const Objections = () => {


    const { settings, setSettings } = useContext(SettingsContext);

    const onChange = ({ item, action }) => {
        //onsole.info("Change", action, item);
    }


    return (
        <>
            <Hero title="Objection handling" text="Suggestion for handling objections in the Lead page" />
            <Container fluid>
                <Row className="mt-2">
                    <Col className="col-6">
                        <Tabs
                            defaultActiveKey="price"
                            id="objections-tab"
                            className="mb-3"
                        >
                            <Tab eventKey="price" title="Price">
                                {settings && settings.closing && settings.closing.objections && settings.closing.objections.price &&
                                    <SimpleGrid allowDelete={true} allowInsert={true} rows={settings.closing.objections.price} onChange={onChange} />
                                }
                            </Tab>
                            <Tab eventKey="value" title="Value">
                                {settings && settings.closing && settings.closing.objections && settings.closing.objections.value &&
                                    <SimpleGrid allowDelete={true} allowInsert={true} rows={settings.closing.objections.value} onChange={onChange} />
                                }
                            </Tab>
                            <Tab eventKey="timing" title="Timing">
                                {settings && settings.closing && settings.closing.objections && settings.closing.objections.timing &&
                                    <SimpleGrid allowDelete={true} allowInsert={true} rows={settings.closing.objections.timing} onChange={onChange} />
                                }
                            </Tab>
                            <Tab eventKey="product" title="Product">
                                {settings && settings.closing && settings.closing.objections && settings.closing.objections.product &&
                                    <SimpleGrid allowDelete={true} allowInsert={true} rows={settings.closing.objections.product} onChange={onChange} />
                                }
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container >
        </>
    )

}