import { useContext } from 'react';

import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';

import { LayoutContext } from '../libs/contexts';

/**
 * A jumbotron-like header with optional buttons and separator
 * @param object options
 * @param object options.icon Icon component, most likely a react-icon component
 * @param string options.title Title, in large type
 * @param string options.text Main text in the body, under the title
 * @param Array options.buttons Optional list of buttons. We expect each element to be a component.
 * @param boolean options.hasSeparator Whether we want to add an horizontal line or not. Defaults to false.
 * @returns object component
 */
export const Hero = ({ icon, title, text, buttons, hasSeparator }) => {

    const { layout } = useContext(LayoutContext);

    return (
        <Container fluid>
            <Row className='mt-2'>
                <Col className={"px-0 pb-1 my-0 " + layout.schemeHeader}>
                    {undefined === icon ?
                        <></>
                        :
                        <>{icon}</>
                    }
                    <h1 className={"display-5 fw-bold " + layout.schemeHeader}>{title}</h1>
                    <p className={"lead mb-4 " + layout.schemeHeader}> {text}</p>
                    {undefined === buttons ?
                        <></>
                        :
                        <div className="d-grid gap-2 d-sm-flex">
                            {buttons.map((button, index) => (
                                <React.Fragment key={index}>{button}</React.Fragment>
                            ))}
                        </div>
                    }

                    {hasSeparator &&
                        <hr />
                    }
                </Col>
            </Row>
        </Container >


    )


}