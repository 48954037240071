import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';

import { Hero } from '../controls/hero';
import {
  LayoutContext,
  LeadsContext,
  ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n.js';

export const Downloads = () => {

    const { layout } = useContext(LayoutContext);
    const { leads } = useContext(LeadsContext);
    const { reportingData } = useContext(ReportingDataContext);
    const [pdfLinks, setPdfLinks] = useState([]);

    useEffect(() => {
        if (reportingData.policies && reportingData.policies.length > 0) {
            setPdfLinks(reportingData.policies);
        }
    }, [reportingData.policies]);

    return (
        <>
            <Hero
                title="Policies"
            />
            <Container>
                <Row>
                    { pdfLinks && pdfLinks.length > 0 ?
                        <>
                            { pdfLinks.map((pdf, index) => (
                                <Col className='col-4' key={ index }>
                                    <Card className='mb-3'>
                                        <Card.Header>
                                            { pdf.first_name ?
                                                <>{ pdf.first_name } { pdf.last_name }</>
                                                :
                                                <>Policy { pdf.id }</>
                                            }
                                            <small className='float-end text-muted'>{ pdf.created }</small>
                                        </Card.Header>
                                        <Card.Body>
                                            <Button target="_blank" href={ pdf.download } variant='outline-primary'><FaFileDownload /> Download a copy</Button>
                                        </Card.Body>
                                        <Card.Footer>
                                            { pdf.facevalue ?
                                                <>{ asCurrency({ amount: pdf.facevalue, dropPennies: true }) }</>
                                                :
                                                <>&nbsp;</>
                                            }
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            )) }
                        </>
                        :
                        <Col>
                            <Badge>No policy yet</Badge>
                        </Col>
                    }
                </Row>
            </Container>


        </>
    );
}


