import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { parseJwt } from '../libs/auth.js';

export const LoginPage = () => {


    const navigate = useNavigate();

    useEffect(() => {
        //navigate('/');
    }, []);

    return (
        <>
            ...
        </>
    );

}


export const LogoutPage = () => {


    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem('session');
        navigate('/');
    }, []);

    return (
        <>
            ...
        </>
    );

}


export const RedirectPage = () => {

    const navigate = useNavigate();

    useEffect(() => {

        if (window.location.hash) {
            const hashValue = window.location.hash;
            const tokenStr = hashValue.substring(1);
            let tokenObj = {};
            let tokenVals = {};
            let tokenList = tokenStr.split("&");
            for (let item of tokenList) {
                let kv = item.split('=');
                tokenObj[kv[0]] = parseJwt(kv[1]);
                tokenVals[kv[0]] = kv[1];
            }
            let tokens = {};
            for (let token of ['id_token', 'access_token', 'refresh_token']) {
                let tokenValue = tokenObj[token];
                tokens[token] = tokenValue;
            }

            let sessionInfo = {};
            for (let [k, v] of Object.entries(tokens)) {
                localStorage.setItem(k, v);
                if (["id_token", "access_token"].includes(k)) {
                    sessionInfo = { ...sessionInfo, ...v };
                    sessionInfo[k] = v;
                }
            }
            sessionInfo.token = tokenVals.access_token;
            localStorage.setItem('session', JSON.stringify({
                email: sessionInfo.email,
                token: tokenVals.access_token,
                auth: sessionInfo,
                created: Date.now()
            }));
            setTimeout(() => {
                navigate("/");
            }, 1000);


        } else {
            navigate("/");
        }

    }, []);

    return (
        <><small className='text-muted'>Authenticating...</small></>
    );

}
