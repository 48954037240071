import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Badge,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
} from 'react-bootstrap';

import { ReportingDataContext } from '../libs/contexts.js';
import { asNumber } from '../libs/i18n.js';

export const MutualSummary = ({ }) => {

    const { reportingData } = useContext(ReportingDataContext);
    const [insights, setInsights] = useState([]);


    useEffect(() => {

        if (reportingData.demographics) {

            let newInsights = [];
            let refPolicies = 0;
            let biggestValue = 0;
            let biggestBroker = '';

            for (let item of reportingData.demographics) {

                if (undefined !== item.id && 'summary' === item.id) {
                    refPolicies = item.commercial_policies;
                }

            }

            for (let item of reportingData.demographics) {
                if (undefined !== item.id && 'combined' === item.id) {
                    if (item.car_policies) {
                        let record = {};

                        record.metric = Math.round(100 * (item.car_policies / item.policies), 2).toString() + '%';
                        record.title = "Car policies";
                        record.message = `There are ${item.car_policies} car policies on average in areas surrounding leads`;
                        newInsights.push(record)
                    }
                    if (item.commercial_policies) {
                        let record = {};

                        record.metric = asNumber({ value: Math.round(item.commercial_policies, 2) });
                        record.title = "Commercial policies";
                        if (item.commercial_policies > refPolicies) {
                            record.message = `The number of commercial policies in areas surrounding leads is lower than the P&C average.`;
                        } else {
                            record.message = `The number of commercial policies in areas surrounding leads is higher than the P&C average.`;
                        }

                        newInsights.push(record)
                    }
                }
            }



            for (let item of reportingData.demographics) {

                if (undefined !== item.id && !['summary', 'combined'].includes(item.id)) {
                    if (item.policies && item.policies > biggestValue) {
                        biggestValue = item.policies;
                        biggestBroker = item.id;
                    }
                }

            }

            if (biggestValue > 0) {
                let record = {};
                record.metric = asNumber({ value: Math.round(biggestValue, 0) });
                record.title = "Largest number of P&C policies";
                record.message = `There are ${record.metric} P&C policies in areas associated with ${biggestBroker}`;
                newInsights.push(record)

            }

            setInsights(newInsights);

        }


    }, [reportingData.demographics])

    return (
        <Card className='card-summary-alt'>
            <Card.Header>P&C cross-selling hotspots</Card.Header>
            <Card.Body className='px-0 py-0'>
                <Container fluid>

                    <Row className='mt-4 mb-4'>
                        <Col>
                            { insights &&
                                <ListGroup>
                                    { insights.map((insight, index) => (

                                        <ListGroup.Item
                                            key={ index }
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{ insight.title }</div>
                                                { insight.message }
                                            </div>
                                            <Badge bg="primary" pill>
                                                { insight.metric }
                                            </Badge>
                                        </ListGroup.Item>

                                    )) }
                                </ListGroup>

                            }
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            <Card.Footer>
                These numbers are based on the P&C book of business aggregated at the postal code level.
            </Card.Footer>
        </Card>

    );
}

