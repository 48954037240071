
export const asCurrency = ({ amount, currency, locale, dropPennies, verbose }) => {

    if (undefined === amount) return null;

    if (undefined === currency) {
        currency = 'CAD';
    }

    if (undefined === locale) {
        locale = 'en-CA';
    }

    let currencyString = new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(amount);

    if (dropPennies) {
        currencyString = currencyString.slice(0, currencyString.length - 3);
    }

    return currencyString;

}

export const asDateString = ({ date, locale, verbose }) => {

    if (undefined === date) {
        date = Date.now();
    }

    if (undefined === locale) {
        locale = 'en';
    }

    return new Intl.DateTimeFormat(locale).format(date);

}

export const asNumber = ({ value, locale, verbose }) => {

    if (undefined === value) return null;

    if (undefined === locale) {
        locale = 'en-CA';
    }

    let numberString = new Intl.NumberFormat(locale).format(value);

    return numberString;

}