import React, { useContext } from 'react';

import {
    Card,
    ListGroup,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {
    LayoutContext,
    ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';

export const FeaturedLeadsCard = ({ footer }) => {

    const { layout } = useContext(LayoutContext);
    const { reportingData } = useContext(ReportingDataContext);
    const navigate = useNavigate();

    const showQuote = (id) => {
        navigate('/leads/' + id);
    }

    const displayValue = ({ value }) => {

        if (value) {
            return asCurrency({ amount: value, dropPennies: true });
        } else {
            return "";
        }


    }

    return (


        <Card>
            <Card.Header>
                Close a sale today!
            </Card.Header>
            <Card.Body>

                { reportingData.reports && reportingData.reports.latest &&
                    <>
                        <ListGroup variant="flush">
                            { reportingData.reports.latest.map((lead, index) => (
                                <ListGroup.Item key={ index } action onClick={ () => showQuote(lead.quote) }><span className="text-warning">{ displayValue({ value: lead.facevalue }) } </span> { lead.first_name ? lead.first_name + ", " : "" } { lead.city } { lead.province } { lead.gender ? `(${lead.gender})` : `` }  <span className="float-end text-muted">{ lead.date }</span></ListGroup.Item>
                            )) }
                        </ListGroup>

                    </> }


            </Card.Body>

        </Card>



    )

}
