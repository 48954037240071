import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Badge,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
} from 'react-bootstrap';

import { ReportingDataContext } from '../libs/contexts.js';
import {
  asCurrency,
  asNumber,
} from '../libs/i18n.js';

export const DemographicsSummary = ({ }) => {

    const { reportingData } = useContext(ReportingDataContext);
    const [insights, setInsights] = useState([]);


    useEffect(() => {

        if (reportingData.demographics) {
            for (let item of reportingData.demographics) {
                if (undefined !== item.id && 'combined' === item.id) {
                    let newInsights = [];

                    if (item.average_income) {
                        let record = {
                            title: "Average income"
                        };
                        record.metric = asCurrency({ amount: item.average_income, dropPennies: true });
                        let total = item.employed + item.self_employed;
                        let pct = Math.round(100 * (item.self_employed / total), 0);
                        record.message = `${pct}% of residents are self-employed.`
                        newInsights.push(record);
                    }

                    if (item.rented) {
                        // rental
                        let record = {
                            title: "Rent or Own?"
                        };
                        let total = item.rented + item.owned;
                        record.metric = (Math.round(100 * (item.rented / total), 0)).toString() + "%";

                        record.message = `${record.metric} of residents are renters, on average.`

                        newInsights.push(record);
                    }

                    if (item.population) {

                        let biggestValue = 0;
                        let biggestLabel = "";
                        for (let segment of ['population_20s', 'population_30s', 'population_40s', 'population_50s']) {
                            if (undefined !== item[segment]) {
                                if (item[segment] > biggestValue) {
                                    console.log("Bigger", item[segment]);
                                    biggestValue = item[segment];
                                    biggestLabel = segment.split('_').pop();
                                } else {
                                    console.info("no bigger", item[segment]);
                                }
                            }
                        }
                        let record = { title: "Most common age group" };
                        record.metric = biggestLabel;
                        let value = asNumber({ value: biggestValue });

                        record.message = `The most common age group is people in their ${biggestLabel}`;
                        newInsights.push(record);


                    }


                    setInsights(newInsights);
                }
            }
        }


    }, [reportingData.demographics])

    return (
        <Card className='card-summary-alt'>
            <Card.Header>In the areas surrounding your leads</Card.Header>
            <Card.Body>
                <Container fluid>

                    <Row className='mt-4 mb-4'>
                        <Col>
                            { insights &&
                                <ListGroup>
                                    { insights.map((insight, index) => (

                                        <ListGroup.Item
                                            key={ index }
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                        >
                                            <div className="ms-2 me-auto">
                                                <div className="fw-bold">{ insight.title }</div>
                                                { insight.message }
                                            </div>
                                            <Badge bg="primary" pill>
                                                { insight.metric }
                                            </Badge>
                                        </ListGroup.Item>

                                    )) }
                                </ListGroup>

                            }
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
            <Card.Footer>
                These numbers are based on statistics at the postal code level, across all leads from all participating brokers.
            </Card.Footer>
        </Card>

    );
}

