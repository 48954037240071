import React, {
    useEffect,
    useState,
} from 'react';

import {
    Badge,
    Card,
    ListGroup,
} from 'react-bootstrap';
import { BsArrowRightShort } from 'react-icons/bs';

import { LeadFeatureDisabled } from './leads';

export const Timeline = ({ lead }) => {


    const [screens, setScreens] = useState(false);


    useEffect(() => {



        if (undefined !== lead.happyPath) {

            let steps = [];

            for (let [parent, child] of Object.entries(lead.happyPath)) {

                if (undefined !== lead[parent + "_start"]) {
                    let duration = 0;
                    if (undefined !== lead[parent + "_end"]) {
                        duration = Math.round((lead[parent + "_end"] - lead[parent + "_start"]) / 1000, 2);
                    }
                    if (duration) {
                        steps.push({ name: parent, duration: duration + " sec." });
                    } else {
                        steps.push({ name: parent, duration: "few sec." });
                    }
                }
            }

            setScreens(steps);

        }


    }, [lead.happyPath]);




    return (
        <Card>
            <Card.Header>
                Timeline
            </Card.Header>
            <Card.Body>
                {screens &&
                    <>
                        {screens.map((screen, index) => (
                            <React.Fragment key={index}>
                                <Badge key={index} bg="secondary">{screen.name} <Badge className='text-muted' pill bg="light">{screen.duration}</Badge></Badge> <BsArrowRightShort />
                            </React.Fragment>
                        ))}
                        <Badge bg="secondary">end</Badge>
                    </>
                }
            </Card.Body>
        </Card>
    )

}



export const VerticalTimeline = ({ lead }) => {


    const [screens, setScreens] = useState(false);


    useEffect(() => {



        if (undefined !== lead.happyPath) {

            let steps = [];

            for (let [parent, child] of Object.entries(lead.happyPath)) {

                if (undefined !== lead[parent + "_start"]) {
                    let duration = 0;
                    if (undefined !== lead[parent + "_end"]) {
                        duration = Math.round((lead[parent + "_end"] - lead[parent + "_start"]) / 1000, 2);
                    }
                    if (duration) {
                        steps.push({ name: parent, duration: duration + " sec." });
                    } else {
                        steps.push({ name: parent, duration: "few sec." });
                    }
                }
            }

            setScreens(steps);

        }


    }, [lead.happyPath]);




    return (
        <Card>
            <Card.Header>
                Timeline
            </Card.Header>
            <Card.Body>
                {screens &&
                    <ListGroup>
                        {screens.map((screen, index) => (
                            <ListGroup.Item key={index}>{screen.name}
                                <span className="float-end">{screen.duration}</span>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                }
            </Card.Body>
        </Card>
    )

}