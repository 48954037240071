import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { init } from 'echarts';
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  BsBarChartLine,
  BsCartCheck,
  BsCloudDownload,
  BsGearFill,
  BsStar,
} from 'react-icons/bs';

import { LayoutContext } from '../libs/contexts';
import { HistoryChartCard } from './history';
import { InsightsTable } from './insights';
import { InteractiveMap } from './map';
import { Province } from './province';

export const Reports = () => {

    const { layout } = useContext(LayoutContext);

    return (
        <>
            <Container fluid className="mt-4 mb-4">

                <Row>
                    <Col className='col-md-12 col-lg-6'>
                        <HistoryChartCard footer='none' />
                    </Col>
                    <Col className='col-md-12 col-lg-6'>
                        <InsightsTable />
                    </Col>
                </Row>

            </Container>
            {/* 
            <hr />

            <Container fluid>
                <Row>
                    <Col>
                        <InteractiveMap />
                    </Col>
                    <Col>
                        <Province />
                    </Col>
                </Row>
            </Container> */}


        </>
    );
}

