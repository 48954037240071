import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
  BsPlusCircle,
  BsXCircle,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';
import uniqid from 'uniqid';

import { SettingsContext } from '../libs/contexts';

export const SimpleGrid = ({ rows, onChange, allowInsert, allowDelete, readOnly, showId, onSelect }) => {

    const [hasDelete, setHasDelete] = useState(false);
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([]);
    const [gridApi, setGridApi] = useState();

    useEffect(() => {

        if (rows && Array.isArray(rows)) {
            let isEditable = false;
            if (undefined === readOnly || !readOnly) {
                isEditable = true;
            }
            let newColumns = [];
            for (let [key, value] of Object.entries(rows[0])) {
                if ("id" === key) {
                    newColumns.push({ field: "id", filter: false, resizable: false, editable: false, hide: undefined === showId ? true : !showId, suppressToolPanel: true });
                } else {
                    newColumns.push({ field: key, filter: true, resizable: true, sortable: true, editable: isEditable, flex: 1 });
                }
            }
            setColumnDefs(newColumns);
            setRowData(rows);
        }

    }, [rows]);


    const onGridReady = (e) => {
        setGridApi(e.api);
    }

    const onCellValueChanged = (e) => {

        let newRows = [];
        let found = false;
        for (let row of rows) {
            if (row.id !== e.data.id) {
                newRows.push(row);
                found = true;
            } else {
                newRows.push({ ...e.data });
            }
        }
        if (!found) {
            newRows.push({ ...e.data });
        }

        onChange({ item: e.data, action: 'update' });
    }

    const onSelection = (e) => {

        let selection = gridApi.getSelectedRows();

        if (undefined !== onSelect) {
            onSelect(selection);
        }

        if (selection.length > 0) {
            setHasDelete(true);
        } else {
            setHasDelete(false);
        }

    }

    const addRow = () => {
        let lastRow = gridApi.getDisplayedRowAtIndex(gridApi.getLastDisplayedRow());
        let newRow = {};
        for (let [key, value] of Object.entries(lastRow.data)) {
            if ("id" === key) {
                newRow.id = uniqid();
            } else {
                newRow[key] = null;
            }
        }

        gridApi.applyTransaction({
            add: [newRow],
            addIndex: 0
        });
        gridApi.getDisplayedRowAtIndex(0).setSelected(true);

    }

    const deleteRow = () => {

        let selection = gridApi.getSelectedRows();

        if (1 === selection.length) {
            gridApi.applyTransaction({
                remove: [selection[0]]
            });
            onChange({ item: selection[0], action: 'remove' });
        }

    }

    return (

        <Container fluid>
            <Row>
                <Col>
                    <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
                        <AgGridReact
                            skipHeaderOnAutoSize={true}
                            onRowSelected={onSelection}
                            rowSelection='single'
                            onCellValueChanged={onCellValueChanged}
                            onGridReady={onGridReady}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            ref={gridRef}></AgGridReact>
                    </div>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col>
                    {allowInsert && !readOnly &&
                        <Button onClick={addRow} variant="outline-primary"><BsPlusCircle /> Insert</Button>
                    }
                    &nbsp;
                    {allowDelete && !readOnly && hasDelete &&
                        <>
                            <Button onClick={deleteRow} variant="outline-danger"><BsXCircle /> Delete</Button>
                        </>}
                </Col>
            </Row>

        </Container>


    )

}


export const ReadOnlyGrid = ({ height, rows, onSelect }) => {

    const [rowData, setRowData] = useState([]);
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([]);
    const [gridApi, setGridApi] = useState();

    useEffect(() => {

        if (rows && Array.isArray(rows)) {
            let newColumns = [];
            for (let [key, value] of Object.entries(rows[0])) {
                newColumns.push({ field: key, filter: true, resizable: true, sortable: true, editable: false });
            }
            setColumnDefs(newColumns);
            setRowData(rows);
        }

    }, [rows]);


    const onGridReady = (e) => {
        setGridApi(e.api);
    }

    const onSelection = (e) => {

        let selection = gridApi.getSelectedRows();

        if (undefined !== onSelect) {
            onSelect(selection[0]);
        }

    }

    return (

        <div className="ag-theme-alpine" style={{ height: height, width: "100%" }}>
            <AgGridReact
                onRowSelected={onSelection}
                rowSelection='single'
                onGridReady={onGridReady}
                rowData={rowData}
                columnDefs={columnDefs}
                ref={gridRef}></AgGridReact>
        </div>

    )

}
