import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Alert,
  Card,
  Col,
  Container,
  Image,
  Navbar,
  Row,
} from 'react-bootstrap';
import {
  BsBarChartLine,
  BsCartCheck,
  BsCloudDownload,
  BsGearFill,
  BsStar,
} from 'react-icons/bs';
import { VscColorMode } from 'react-icons/vsc';
import {
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import logo from '../bootstrap/logo.png';
import { TopMenu } from '../controls/menus.jsx';
import * as contexts from '../libs/contexts';
import { LayoutContext } from '../libs/contexts';
import { Forbidden } from './forbidden.jsx';
import { AppSession } from './main.jsx';

export const Page = ({ query, desktop }) => {

    const [downloads, setDownloads] = useState(false);
    const [leads, setLeads] = useState(false);
    const [reportingData, setReportingData] = useState(false);
    const [searchData, setSearchData] = useState(false);
    const [layout, setLayout] = useReducer(contexts.stateReducer, {});
    const [session, setSession] = useReducer(contexts.stateReducer, {});
    const [settings, setSettings] = useReducer(contexts.stateReducer, {});

    const [authorizationExpiration, setAuthorizationExpiration] = useState(0);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [cached, setCached] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        let rawSessionData = localStorage.getItem('session');
        if (rawSessionData) {
            let isValid = false;
            let sessionData = JSON.parse(rawSessionData);
            if (undefined !== sessionData.created) {
                const timeDifference = Date.now() - sessionData.created;
                const hours = timeDifference / (1000 * 60 * 60);
                if (hours < 200) {
                    isValid = true;
                }
            }

            if (isValid) {
                //onsole.info("Reloaded session", sessionData);
                setSession(sessionData);
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
            }

        } else {
            //onsole.warn("unauthorized access");
            setIsAuthorized(false);
        }


    }, []);


    return (


        <>
            <>
                { isAuthorized ?

                    <contexts.LayoutContext.Provider value={ { layout, setLayout } }>
                        <contexts.SessionContext.Provider value={ { session, setSession } }>
                            <contexts.LeadsContext.Provider value={ { leads, setLeads } }>
                                <contexts.ReportingDataContext.Provider value={ { reportingData, setReportingData } }>
                                    <contexts.DownloadsContext.Provider value={ { downloads, setDownloads } }>
                                        <contexts.SettingsContext.Provider value={ { settings, setSettings } }>
                                            <contexts.SearchContext.Provider value={ { searchData, setSearchData } }>
                                                <>
                                                    <AppSession query={ query } desktop={ desktop } />
                                                </>
                                            </contexts.SearchContext.Provider>
                                        </contexts.SettingsContext.Provider>
                                    </contexts.DownloadsContext.Provider>
                                </contexts.ReportingDataContext.Provider>
                            </contexts.LeadsContext.Provider>
                        </contexts.SessionContext.Provider>
                    </contexts.LayoutContext.Provider>

                    :
                    <>
                        <Forbidden />
                    </>
                }
            </>
        </>


    )


}



export const SecurePage = () => {

    const { layout } = useContext(LayoutContext);

    useEffect(() => {
        //onsole.log("Loading secure page");
    }, [])

    return (
        <>
            { layout.desktop ?
                <DesktopPage />
                :
                <MobilePage />
            }
        </>
    )

}

const DesktopPage = () => {
    return (<>
        {/* <DesktopMenu /> */ }

        <Container fluid className="mb-4 px-0 mx-0">
            <Row>
                <Col className='px-0 mx-0'>
                    <TopMenu />
                </Col>
            </Row>
            <Row>
                <Col className='bg-primary mb-0 pb-4 pt-4'>
                    <Container fluid>
                        <Row>
                            <Col className="offset-1 col-10" >
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <Card className='card-outlet'>
                                                <Card.Body>
                                                    <Outlet />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>

                </Col>
            </Row>
            <Row className='bg-primary' style={ { minHeight: "400px", overflow: 'hidden' } }>
                <Col>
                    <FooterNotes />
                </Col>
            </Row>
        </Container >
    </>);
}

const FooterNotes = ({ }) => {

    const [show, setShow] = useState(true);

    return (
        <Container>
            <Row>
                <Col className='mx-auto'>
                    <Alert variant="primary" onClose={ () => setShow(false) } dismissible>
                        <Alert.Heading>Want to be notified?</Alert.Heading>
                        <p>
                            If you want to subscribe to email notifications, mobile notifications, or change your LinkAdmin experience in any way, please <a href="mailto:LifeSystemsLink@wawanesa.com">drop us a note</a>!
                        </p>
                    </Alert>
                </Col>
            </Row>
        </Container>


    );

}

const MobilePage = () => {
    return (<>
        {/* <MobileMenu /> */ }

        <Container fluid className="mb-4">
            <Row>
                <Col>
                    <Outlet />
                </Col>
            </Row>
        </Container>
    </>);
}

const DesktopMenu = () => {

    const { layout, setLayout } = useContext(LayoutContext);

    const swapTheme = () => {

        //let elem = document.querySelector('html');

        if (document.documentElement.getAttribute('data-bs-theme') == 'dark') {
            document.documentElement.setAttribute('data-bs-theme', 'light')
        }
        else {
            document.documentElement.setAttribute('data-bs-theme', 'dark')
        }
    }


    return (
        <Navbar>
            <Container fluid className="text-center">
                <Image style={ { width: "140px" } } src={ logo } />
                <Navbar.Brand className="text-danger" href="/">Control<span className="text-warning">Room</span></Navbar.Brand>
                <VscColorMode onClick={ swapTheme } className="float-end clicker" title="Toggle dark/light mode" />
            </Container>
        </Navbar>
    )
}


const MobileMenu = () => {
    return (
        <Navbar className="bg-dark text-white">
            <Container fluid>
                <Navbar.Brand className="text-white" href="/">Link Control<span className="text-info">Room</span></Navbar.Brand>
                <Image className="float-end" style={ { height: "35px" } } src="controlroom/wawanesalife-wb.png" />
            </Container>
        </Navbar>
    )
}

const Sidebar = () => {

    const [screen, setScreen] = useState('dashboard');

    const navigate = useNavigate();
    let location = useLocation();


    useEffect(() => {

        if (location && location.pathname) {
            let screenName = location.pathname.split('/').pop();
            if (screenName && screenName.length > 2) {
                setScreen(screenName);
            } else {
                setScreen('dashboard');
            }
        }

    }, [location]);

    return (
        <>
            <h1 onClick={ () => navigate('/') } className={ "clicker mt-4 menu-" + ("dashboard" === screen ? "active text-info" : "inactive") }><BsStar /></h1>
            <h1 onClick={ () => navigate('/reports') } className={ "clicker mt-4 menu-" + ("reports" === screen ? "active text-info" : "inactive") }><BsBarChartLine /></h1>
            <h1 onClick={ () => navigate('/leads') } className={ "clicker mt-4 menu-" + ("leads" === screen ? "active text-info" : "inactive") }><BsCartCheck /></h1>
            <h1 onClick={ () => navigate('/downloads') } className={ "clicker mt-4 menu-" + ("downloads" === screen ? "active text-info" : "inactive") }><BsCloudDownload /></h1>
            <h1 onClick={ () => navigate('/settings') } className={ "clicker mt-4 menu-" + ("settings" === screen ? "active text-info" : "inactive") }><BsGearFill /></h1>
        </>
    );

}