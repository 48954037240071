import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { init } from 'echarts';
import {
    Badge,
    Button,
    Card,
    Col,
    Container,
    Form,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {
    BsBarChartLine,
    BsCartCheck,
    BsCloudDownload,
    BsFileEarmarkExcel,
    BsFiletypeCsv,
    BsGearFill,
    BsStar,
} from 'react-icons/bs';

import { LayoutContext } from '../libs/contexts';

export const QuoterCard = ({ footer }) => {

    const { layout } = useContext(LayoutContext);

    return (


        <Card>
            <Card.Header>
                Quick quote
            </Card.Header>
            <Card.Body className='disabled text-muted'>

                <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control disabled className='disabled text-muted' type="text" placeholder="Age (18-50)" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Control disabled className='disabled text-muted' type="text" placeholder="Face value (20k-500k)" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Select disabled className='disabled text-muted' aria-label="Default select example">
                            <option>Gender</option>
                            <option value="F">Female</option>
                            <option value="M">Male</option>
                        </Form.Select>
                    </Form.Group>
                </Form>


            </Card.Body>
            {'full' === footer &&
                <Card.Footer>
                    <Button disabled className='disabled text-muted' variant="outline-primary">Generate Link URL</Button>
                    <Badge className="float-end">not available in demo</Badge>
                </Card.Footer>
            }
        </Card>
    )

}

