import { Card } from 'react-bootstrap';

import { Statistics } from './statistics';

export const FaceValueCard = ({ lead }) => {


  return (
    <Card className="mb-2">
      <Card.Header>
        Face value statistics
      </Card.Header>
      <Card.Body>
        { lead && lead.facevalue ?
          <Statistics lead={ lead } />
          :
          <><small className="text-muted">no face value provided</small></>
        }
      </Card.Body>
    </Card>
  )

}
