
export const sortBrokers = ({ brokers }) => {

    const compare = (a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    let sortedBrokers = [];

    if (brokers) {
        for (let [k, v] of Object.entries(brokers)) {
            let broker = { ...v };
            broker.id = k;
            if ('ended' === broker.stage) {
                broker.stage = 'considered';
            }
            sortedBrokers.push(broker);
        };
        sortedBrokers.sort(compare);
    }

    return sortedBrokers;

}

export const convertOffers = ({ valueStr }) => {

    let offers;

    if (valueStr) {
        if (typeof valueStr === 'object') {
            console.info("Already an object");
            offers = { ...valueStr };
        } else {
            try {
                offers = JSON.parse(valueStr);
            } catch (err) {
                console.warn("No offers", valueStr, err);
                offers = {}
            }
        }

    }


    // // we convert the weird Athena format to an object

    // let fields = ['norider', 'nonsmoker', 'term10', 'term20', 'smoker', 'rider', 'monthly', 'proposed', 'recommended', 'base'];
    // let value = valueStr.replaceAll('=', ':');
    // for (let field of fields) {
    //     value = value.replaceAll(field, '"' + field + '"');
    // }
    // value = value.replaceAll('"no"', '"no').replaceAll('"non"', '"non').replaceAll('""', '"');
    // let offers = JSON.parse(value);

    return offers;

}