import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { init } from 'echarts';
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  BsBarChartLine,
  BsCartCheck,
  BsCloudDownload,
  BsGearFill,
  BsStar,
} from 'react-icons/bs';

import { LayoutContext } from '../libs/contexts';
import { Everything } from './everything';
import { FeaturedLeadsCard } from './featured';
import {
  CustomSearch,
  MostRecent,
  TopFacevalue,
  TopMotivated,
} from './top';

export const Leads = () => {

  const { layout } = useContext(LayoutContext);

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col className='col-md-12 col-lg-6'>
          <Container fluid className="mt-4">

            <Row>
              <Col className='col-12'>
                <MostRecent />
              </Col>
            </Row>
            {/* <Row>
              <Col className='col-12'>
                <TopMotivated />
              </Col>
            </Row>
            <Row>
              <Col className='col-12'>
                <TopFacevalue />
              </Col>
            </Row> */}

          </Container>


        </Col>
        <Col className='col-md-12 col-lg-6'>
          <Container fluid className="mt-4">
            <Row>
              <Col className='col-12'>
                <Everything />
              </Col>
            </Row>
          </Container>

        </Col>
      </Row>

    </Container>

  );
}

