import {
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { FaUserLock } from 'react-icons/fa';

import * as config from '../config.js';
import { ActionCard } from '../controls/cards';

export const Forbidden = () => {

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => { setShowMessage(true) }, 850);
  }, []);

  return (
    <Container>
      <Row className="mt-4">
        <Col className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">


          { showMessage &&
            <ActionCard
              title="Authentication required!"
              text="We need to verify your identity"
              icon={ <FaUserLock /> }
              label="Authenticate"
              callback={ config.LoginUrlParameter }
            />
          }



        </Col>
      </Row>

    </Container>);

}