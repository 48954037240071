import React from 'react';

export const stateReducer = (currentState, changes) => {

    // we compose a clean copy
    let newState = { ...currentState };

    if (changes) {

        let updated = false;

        // we merge changes
        for (let [k, v] of Object.entries(changes)) {

            if (undefined === newState[k] || v !== newState[k]) {
                newState[k] = v;
                updated = true;
            }
        }

        if (updated) {
            // we track the latest update
            newState.asOf = Date.now();
        }

    }

    return { ...newState };

}

export const DownloadsContext = React.createContext(
    {
        downloads: '',
        setDownloads: (_) => { }
    }
);

export const LayoutContext = React.createContext(
    {
        layout: '',
        setLayout: (_) => { }
    }
);

export const LeadsContext = React.createContext(
    {
        leads: '',
        setLeads: (_) => { }
    }
);

export const ReportingDataContext = React.createContext(
    {
        reportingData: '',
        setReportingData: (_) => { }
    }
);

export const SearchContext = React.createContext(
    {
        search: '',
        setSearch: (_) => { }
    }
);

export const SessionContext = React.createContext(
    {
        session: '',
        setSession: (_) => { }
    }
);

export const SettingsContext = React.createContext(
    {
        settings: '',
        setSettings: (_) => { }
    }
);

