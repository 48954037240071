import React, {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Badge,
    ListGroup,
} from 'react-bootstrap';
import { BiDollarCircle } from 'react-icons/bi';
import { BsClockHistory } from 'react-icons/bs';
import {
    FaRunning,
    FaSearchPlus,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { BasicCard } from '../controls/cards';
import {
    LayoutContext,
    ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';

const TemporaryDisabled = () => {

    return (
        <>
            <div className="text-center">
                <Badge>not available in demo</Badge>
            </div>
        </>
    )
}

export const MostRecent = () => {

    return (
        <BasicCard header="Most recent" body={ <LeadList category="latest" /> } icon={ <BsClockHistory /> } footer="Latest leads" />
    )

}

export const TopMotivated = () => {

    return (

        <BasicCard header="Most motivated" body={ <LeadList category="motivated" /> } icon={ <FaRunning /> } footer="Longest sessions" />

    )

}


export const TopFacevalue = () => {


    return (
        <BasicCard header="Highest face value" body={ <LeadList category="facevalue" /> } icon={ <BiDollarCircle /> } footer="Leads with the highest face value" />
    )

}

export const CustomSearch = ({ footer }) => {


    return (
        <BasicCard header="Search" body={ <TemporaryDisabled /> } icon={ <FaSearchPlus /> } footer="Free-form search" />
    )

}

const LeadList = ({ category }) => {


    const { reportingData } = useContext(ReportingDataContext);
    const navigate = useNavigate();

    const [records, setRecords] = useState([]);


    const showQuote = (id) => {
        navigate('/leads/' + id);
    }

    const displayValue = ({ value }) => {

        if (value) {
            return "(" + asCurrency({ amount: value, dropPennies: true }) + ") ";
        } else {
            return "";
        }


    }


    useEffect(() => {

        if (reportingData.rows) {
            let allRecords = [...reportingData.rows];
            let newRecords = [];
            switch (category) {
                case 'latest':
                    allRecords.sort((a, b) => (a.started > b.started) ? 1 : ((b.started > a.started) ? -1 : 0))
                    for (let i = 0; i < 5; i++) {
                        if (allRecords.length > 0) {
                            newRecords.push(allRecords.pop());
                        }
                    }
                    break;
            }
            setRecords(newRecords);
        }

    }, [reportingData.rows, category]);


    return (
        <>
            { records &&
                <>
                    <ListGroup variant="flush">
                        { records.map((lead, index) => (
                            <ListGroup.Item key={ index } action onClick={ () => showQuote(lead.quote) }><span className="text-warning">{ displayValue({ value: lead.facevalue }) }</span> { lead.first_name } { lead.city } <span className="float-end text-muted">{ lead.date }</span></ListGroup.Item>
                        )) }
                    </ListGroup>

                </> }


        </>
    );

}

