import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';

import { SettingsContext } from '../libs/contexts';

export const SalesTips = () => {

  const { settings } = useContext(SettingsContext);
  const [advice, setAdvice] = useState(false);
  const [statements, setStatements] = useState(false);
  const [more, setMore] = useState(0);

  useEffect(() => {

    if (settings && undefined !== settings.closing && undefined !== settings.closing.tips) {
      setStatements([...settings.closing.tips]);
    }

  }, [settings]);

  useEffect(() => {

    if (statements) {
      let index = Math.floor(Math.random() * statements.length);
      setAdvice(statements[index]);
    }

  }, [statements, more]);

  return (
    <Card>
      <Card.Header>Sales tips
        <small onClick={() => setMore(Date.now())} className="clicker float-end"><MdRefresh /></small>
      </Card.Header>
      <Card.Body>
        {advice &&
          <>
            <p>
              {advice.quote}
            </p><i>- {advice.author}</i>
          </>
        }
      </Card.Body>
    </Card>
  )

}
