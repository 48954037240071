import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { BasicCard } from '../controls/cards';
import { ReadOnlyGrid } from '../controls/grids';
import {
  LayoutContext,
  ReportingDataContext,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';

export const Everything = () => {

  const { reportingData } = useContext(ReportingDataContext);
  const navigate = useNavigate();

  const onSelect = (lead) => {

    navigate('/leads/' + lead.quote);
  }

  return (
    <>
      <Card>
        <Card.Header>All leads</Card.Header>
        <Card.Body className="px-0 py-0">
          {reportingData && reportingData.rows &&
            <ReadOnlyGrid height={720} rows={reportingData.rows} onSelect={onSelect} />}
        </Card.Body>
        <Card.Footer>All leads (no time limit)</Card.Footer>
      </Card>

    </>
  )
}